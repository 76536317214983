import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { usePromiseTracker } from "react-promise-tracker";
import LoaderComponent from "./common/components/loader/loader.component";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return promiseInProgress ? <GlobalLoader /> : null;
};

const GlobalLoader = () => {
  return (
    <div className="global-loader-wrapper">
      <div className="global-loader">
        <img
          className="logo"
          src="logo.png"
          alt="logo: graficzne przedstawienie psa i kota wpisane w niebieskie koło"
        />
        <LoaderComponent />
      </div>
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
    <LoadingIndicator />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
