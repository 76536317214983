import { ReplaySubject } from "rxjs";
import Doctor from "../models/doctor";

export class DoctorService {
    public static doctor$: ReplaySubject<Doctor> | null;

    public static removeSubject() {
        this.doctor$ = null;
    }

    public static createSubject() {
        this.doctor$ = new ReplaySubject<Doctor>();
    }
}