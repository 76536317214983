import { Component, ReactElement } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../../context/auth";
import "./header.component.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";

export default class Header extends Component {
  static contextType = AuthContext;

  render(): ReactElement {
    return (
      <div className="container">
        <div className="header row justify-content-start align-items-center">
          <div className="col logo-wrapper">
            <Link to="/home" className="row align-items-center">
              <img
                className={
                  this.context.isAuthenticated
                    ? "logo-header"
                    : "logo-header logo-header-on-homepage"
                }
                src="logo.png"
                alt="logo: graficzne przedstawienie psa i kota wpisane w niebieskie koło"
              />
              <button className="header-button">
                VIVA
                <br />
                Przychodnia
                <br />
                Weterynaryjna
              </button>
            </Link>
          </div>
          {this.context.user &&<div className="name-wrapper">
            <span>
              <FontAwesomeIcon icon={faUserAlt} color="white" />
              {this.context.user?.email}
            </span>
          </div>}
        </div>
      </div>
    );
  }
}
