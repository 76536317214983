import { Component, ReactElement } from "react";
import "./footer.component.scss";

export default class Footer extends Component {
  render(): ReactElement {
    return (
      <footer className="footer">
        <div className="container flex-container">
          <div className="row row-footer">
            <div className="col-1 col-footer">
              <span style={{ fontWeight: "bold" }}>Kontakt</span>
            </div>
            <div className="col ps-4">
              <div className="row">
                <span>tel. +48 503 103 404</span>
              </div>
              <div className="row">
                <span>tel. +48 571 281 704</span>
              </div>
              <div className="row">
                <span>email: kontakt@vivawet.pl</span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
