export default function authHeader() {
    const tokenStr = localStorage.getItem("token");
    let token = null;

    if (tokenStr && tokenStr !== "undefined")
      token = JSON.parse(tokenStr);
  
    if (token) {
      return { "Content-Type": "application/json", Authorization: 'Bearer ' + token };
    } else {
      return { "Content-Type": "application/json" };
    }
  }
  