import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

toast.configure();

export default class AlertService {

  public static warning(msg: string, position = toast.POSITION.TOP_RIGHT, autoClose: (number | false | undefined) = 5000): void {
    toast.warning(msg, { position, autoClose });
  }

  public static error(msg: string, position = toast.POSITION.TOP_RIGHT, autoClose: (number | false | undefined) = 5000): void {
    toast.error(msg, { position, autoClose });
  }

  public static success(msg: string, position = toast.POSITION.TOP_RIGHT, autoClose: (number | false | undefined) = 5000): void {
    toast.success(msg, { position, autoClose });
  }

  public static info(msg: string, position = toast.POSITION.TOP_RIGHT, autoClose: (number | false | undefined) = 5000): void {
    toast.info(msg, { position, autoClose });
  }
}
