import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./styles/main.scss";
import "./App.scss";
import { Switch, Route, HashRouter, Redirect } from "react-router-dom";
import AuthContext, { AuthProvider } from "./context/auth";

import React, { Component, lazy, Suspense } from "react";
import LoaderComponent from "./common/components/loader/loader.component";
import UtilityService from "./common/services/utility.service";
import Header from "./components/main/header/header.component";
import Footer from "./components/main/footer/footer.component";

const PasswordChange = lazy(
  () => import("./common/components/form-elements/password-change/password-change.component")
);
const LoginPage = lazy(
  () => import("./pages/common-pages/registration-pages/login-page/login-page.component")
);
const HomePage = lazy(() => import("./pages/common-pages/home-page/home-page"));
const RegistrationConfirmPage = lazy(
  () =>
    import("./pages/common-pages/registration-pages/registration-confirm-page/registration-confirm-page.component")
);
const ResetForgottenPasswordPage = lazy(
  () =>
    import("./pages/common-pages/registration-pages/reset-forgotten-password-page/reset-forgotten-password-page.component")
);
const ResetPasswordConfirmPage = lazy(
  () =>
    import("./pages/common-pages/registration-pages/reset-password-confirm-page/reset-password-confirm-page.component")
);

const App = () => {
  UtilityService.initLocalizer();
  return (
    <div className="App">
      <AuthProvider>
        <Suspense fallback={LoaderComponent}>
          <HashRouter>
            <Header />
            <Routes />
          </HashRouter>
        </Suspense>
      </AuthProvider>
      <Footer />
    </div>
  );
};

class Routes extends Component {
  static contextType = AuthContext;
  componentDidMount(): void {
    this.context.readUserFromStorage();
  }

  render() {
    return (
      <Switch>
        <Route
          path={"/registration/:token"}
          component={RegistrationConfirmPage}
        ></Route>
        <Route
          path={"/request-password-reset"}
          component={ResetForgottenPasswordPage}
        ></Route>
        <Route
          path={"/reset-password/:token"}
          component={ResetPasswordConfirmPage}
        ></Route>
        <ProtectedRoute
          path={"/home"}
          auth={this.context.isAuthenticated}
          component={HomePage}
        />
        <ProtectedRoute
          path={"/password-change"}
          auth={this.context.isAuthenticated}
          component={PasswordChange}
        />
        <ProtectedLogin
          path={"/"}
          auth={this.context.isAuthenticated}
          component={LoginPage}
        />
        <Redirect to="/" push />
      </Switch>
    );
  }
}

const ProtectedRoute = ({ auth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => (auth ? <Component /> : <Redirect to="/" push/>)}
    />
  );
};

const ProtectedLogin = ({ auth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => (!auth ? <Component /> : <Redirect to="/home" push/>)}
    />
  );
};

export default App;
