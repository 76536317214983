import moment from "moment";
import { momentLocalizer } from "react-big-calendar";
import "moment/locale/pl";

moment.locale("pl");
export default class UtilityService {
  public static localizer;

  public static calendarMin = moment("8:00am", "h:mma").toDate();

  public static calendarMax = moment("9:00pm", "h:mma").toDate();

  public static findIntersection(arrays): any[] {
    arrays = arrays.filter((array) => array !== undefined);
    if (arrays.length === 0) return [];
    return arrays.shift().reduce((res, v) => {
      if (
        res.indexOf(v) === -1 &&
        arrays.every((a) => {
          return a.indexOf(v) !== -1;
        })
      )
        res.push(v);
      return res;
    }, []);
  }

  public static initLocalizer() {
    UtilityService.localizer = momentLocalizer(moment);
  }

  public static formatDate(d: Date, format: string): string {
    return moment(d).format(format);
  }

  public static addMinutes(d: Date, minutes: number): Date {
    return moment(d).add(minutes, "m").toDate();
  }

  public static removeMinutes(d: Date, minutes: number): Date {
    return moment(d).subtract(minutes, "m").toDate();
  }

  public static addSeconds(d: Date, seconds: number): Date {
    return moment(d).add(seconds, "s").toDate();
  }

  public static removeSeconds(d: Date, seconds: number): Date {
    return moment(d).subtract(seconds, "s").toDate();
  }

  public static subtractDays(d: Date, days: number): Date {
    return moment(d).subtract(days, "d").toDate();
  }

  public static addDays(d: Date, days: number): Date {
    return moment(d).add(days, "d").toDate();
  }

  public static parseTimestampFromBackend(timestampStr) {
    return new Date(new Date(timestampStr).getTime() + (new Date(timestampStr).getTimezoneOffset() * 60 * 1000));
  };


  public static parseTimestampToBackend(timestampStr) {
    return new Date(new Date(timestampStr).getTime() - (new Date(timestampStr).getTimezoneOffset() * 60 * 1000));
  };
}
