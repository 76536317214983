import axios from "axios";
import authHeader from "./header.service";
import { trackPromise } from 'react-promise-tracker';

const baseUrl = process.env.REACT_APP_BACKEND_HOST || "https://api.dev.vivawet.pl";

export default class HttpService {
  static get(url: string, track = true): Promise<any> {
    if (track) {
      return trackPromise(axios.get(`${baseUrl}/${url}`, {
        headers: authHeader()
      }).then(res => res.data).catch(err => { throw (err.request?.response && err.request?.response[0] !== '<') ? JSON.parse(err.request.response).message : err }));
    } else {
      return axios.get(`${baseUrl}/${url}`, {
        headers: authHeader()
      }).then(res => res.data).catch(err => { throw (err.request?.response && err.request?.response[0] !== '<') ? JSON.parse(err.request.response).message : err });
    }
  }

  static post(
    url: string,
    body: any, track = true
  ): Promise<any> {
    if (track) {
      return trackPromise(axios.post(`${baseUrl}/${url}`, body, {
        headers: authHeader()
      }).then(res => res.data).catch(err => { throw (err.request?.response && err.request?.response[0] !== '<') ? JSON.parse(err.request.response).message : err }));
    }
    return axios.post(`${baseUrl}/${url}`, body, {
      headers: authHeader()
    }).then(res => res.data).catch(err => { throw (err.request?.response && err.request?.response[0] !== '<') ? JSON.parse(err.request.response).message : err });
  }

  static put(url: string, body: any, track = true): Promise<any> {
    if (track) {
      return trackPromise(axios.put(`${baseUrl}/${url}`, body, {
        headers: authHeader(),
      }).then(res => res.data).catch(err => { throw (err.request?.response && err.request?.response[0] !== '<') ? JSON.parse(err.request.response).message : err }));
    }
    return axios.put(`${baseUrl}/${url}`, body, {
      headers: authHeader(),
    }).then(res => res.data).catch(err => { throw (err.request?.response && err.request?.response[0] !== '<') ? JSON.parse(err.request.response).message : err });
  }

  static delete(
    url: string, track = true
  ): Promise<any> {
    if (track) {
      return trackPromise(axios.delete(`${baseUrl}/${url}`, {
        headers: authHeader()
      }).then(res => res.data).catch(err => { throw (err.request?.response && err.request?.response[0] !== '<') ? JSON.parse(err.request.response).message : err }));
    }
    return axios.delete(`${baseUrl}/${url}`, {
      headers: authHeader()
    }).then(res => res.data).catch(err => { throw (err.request?.response && err.request?.response[0] !== '<') ? JSON.parse(err.request.response).message : err });
  }
}
